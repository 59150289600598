import React from 'react';
import { Avatar, Box, Typography, IconButton, Grid, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';
import profileImage from '../images/S__20185094.png';
import GameLink from './GameLink'; // GameLinkをインポート

const Profile: React.FC = () => {
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          backgroundColor: '#f5f5f5',
          padding: 4,
        }}
      >
        {/* Frame for the profile */}
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            borderRadius: 2,
            textAlign: 'center',
            backgroundColor: '#fff', // Frame background color
            maxHeight: '90vh', // Set a maximum height for the Paper
            width: '80%', // Make the Paper width full
            maxWidth: '900px', // Optional: Set a max width for the Paper
            margin: '0 auto', // 中央揃え
          }}
        >
          {/* アイコン (自分の顔写真やアバターなど) */}
          <Avatar
            alt="takaryo1010"
            src={profileImage} // 自分の画像を設定
            sx={{ width: 180, height: 180, mb: 2, mx: 'auto' }} // Center the avatar
          />

          {/* 自己紹介文 */}
          <Typography variant="h4" gutterBottom>
            Hello, I'm takaryo1010
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            X(Twitter) と GitHub フォロー待ってま～す。
          </Typography>

          {/* SNS リンク */}
          <Grid container justifyContent="center" spacing={2} sx={{ mt: 3 }}>
            <Grid item>
              <IconButton
                href="https://x.com/takaryo1010" // X (Twitter) のリンク
                target="_blank"
                rel="noopener noreferrer"
                aria-label="X (Twitter)"
              >
                <FontAwesomeIcon icon={faXTwitter} size="2x" style={{ color: '#333' }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                href="https://github.com/takaryo1010" // GitHub のリンク
                target="_blank"
                rel="noopener noreferrer"
                aria-label="GitHub"
              >
                <FontAwesomeIcon icon={faGithub} size="2x" style={{ color: '#333' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>

      </Box>
      <GameLink />

    </div>
  );
};

export default Profile;

