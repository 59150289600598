import React from 'react';
import { Box, IconButton } from '@mui/material';
import { IoLogoGameControllerB } from 'react-icons/io';


const GameLink: React.FC = () => {
  const pathname = "http://160.251.177.195"+"/game";
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {/* ゲームリンク */}
      <IconButton
        href = {pathname} // 1つ目のゲームリンク
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Game 1"
        sx={{ color: '#dee0df', '&:hover': { bgcolor: '#03BE81' } }}
      >
        <IoLogoGameControllerB size={80} />
      </IconButton>

    </Box>
  );
};

export default GameLink;
